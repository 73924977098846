<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-edit"></i> การแก้ปัญหา</h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(editHandler)">
              <h6 class="heading-small text-muted mb-4">จัดการปัญหา</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-4">
                          <label class="form-label">แก้ไขโดย</label>
                          <Select2 v-model="item.user_solver"
                                    :options="user_solver"
                                    :settings="{multiple: false}"
                                    placeholder="แก้ไขโดย"
                                    class="mb-4"/>
                          <base-input name="issue_type_id" v-model="item.user_solver" :rules="{required: true}" class="custom-input-valid-datepicker"></base-input>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-4">
                          <label class="form-label">แก้ไขอย่างไร</label>
                          <base-input name="solution" placeholder="แก้ไขอย่างไร" v-model="item.solution" :rules="{required: true}"></base-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="my-4">
              </div>
              <div class="float-right d-flex">
                <span><router-link :to="'/issue'" class="nav-link text-underline"> กลับ</router-link></span>
                <b-button type="submit" variant="primary" class="style_btn" v-if="permission.actionData.flag_update"><i class="fa fa-check"></i> บันทึก</b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SessionStorageUtils from '../../../util/sessionStorageUtils';
export default {
  name: 'issue-view-page-resolve-issue',
  data () {
    return {
      permission:{
        appSlug: 'issue',
        actionData: {}
      },
      item: {},
      id: '',
      profile: {},
      user_solver: []
    }
  },
  methods: {
    async editHandler () {
      const params = {solution: this.item.solution};
      const result = await this.HttpServices.putData(`/ab_issue/updateSolveStatus/${this.item.id}`, params);
      if(result&&result.status.code=="200"){
        this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
          this.$router.push('/issue');
        });
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
    },
    onBackHandler(){
      this.$router.push("/issue");
    },

    async getItem(){
      const result = await this.HttpServices.getData(`/ab_issue/${this.id}`);
      if(result&&result.status.code=="200"){
        this.item = result.data;
      }else{
        this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูลปัญหา`, ()=>{
          this.$router.push('/issue');
        });
      }
    }
  },
  async mounted () {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
    this.id = await this.SessionStorageUtils.getSession("page-issue");
    this.profile = await SessionStorageUtils.decodeUser();
    this.user_solver = await this.HttpServices.getMasterData(`/master/getUsers`);
    await this.getItem();
  }
}
</script>
